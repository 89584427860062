import { Form } from 'react-bootstrap';

const YesNoRadio = ({
	label,
	labelClass,
	name,
	setter,
	defaultValue,
	yesOption = 'Yes:',
	noOption = 'No:',
}) => {
	return (
		<div className="yes-no-radio">
			<Form.Label className={labelClass} htmlFor={name}>
				{label}
			</Form.Label>
			<Form.Group>
				<Form.Label>{yesOption}</Form.Label>
				<Form.Check
					inline
					type="radio"
					name={name}
					onChange={(e) => setter(e.target.checked)}
					defaultChecked={defaultValue === 'yes' ? true : false}
				/>
				<Form.Label>{noOption}</Form.Label>
				<Form.Check
					inline
					type="radio"
					name={name}
					onChange={(e) => setter(!e.target.checked)}
					defaultChecked={defaultValue === 'no' ? true : false}
				/>
			</Form.Group>
		</div>
	);
};
export default YesNoRadio;
