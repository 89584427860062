import { useState, useEffect } from 'react';
import { Card, Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import api from '../AxiosConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheckCircle,
	faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import LoadingOverlay from '../components/LoadingOverlay';
import validateEmail from '../utils/validateEmail';

const WillsTrustsPNC = () => {
	const navigate = useNavigate();
	const [termsAccepted, setTermsAccepted] = useState(false);
	const [form, setForm] = useState({
		firstname: '',
		lastname: '',
		email: '',
		practiceArea: 'willstrusts',
	});
	const [sendingMessage, setSendingMessage] = useState(false);
	const [submissionSuccess, setSubmissionSuccess] = useState(false);
	const [submissionFailure, setSubmissionFailure] = useState(false);
	const [resend, setResend] = useState(false);
	const [errors, setErrors] = useState({});

	const handleForm = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};

	const handleSubmit = () => {
		setInitialSubmit(true);
		//Need to validate the email address
		if (validateInputs()) {
			setSendingMessage(true);
			window.scroll(0, 0);
			//Send the email
			api
				.post('pnc-terms', form)
				.then((res) => {
					setSendingMessage(false);
					if (res.status === 200) {
						setSubmissionSuccess(true);
						if (res.data.resend) {
							setResend(true);
						}
					}
				})
				.catch((err) => {
					setSendingMessage(false);
					setSubmissionFailure(true);
					console.log(err);
				});
		}
	};

	const validateInputs = () => {
		let errors = {};
		if (form.firstname === '') {
			errors = { ...errors, firstname: 'Please provide your First Name.' };
		}
		if (form.lastname === '') {
			errors = { ...errors, lastname: 'Please provide your Last Name.' };
		}
		if (form.email === '') {
			errors = { ...errors, email: 'Please provide your Email Address.' };
		} else {
			if (!validateEmail(form.email)) {
				errors = { ...errors, email: 'Please provide a valid Email Address.' };
			}
		}

		//update state once
		setErrors(errors);
		return Object.keys(errors).length === 0;
	};

	const [initialSubmit, setInitialSubmit] = useState(false); //track first time the submit button is clicked to trigger validation
	//Validate inputs on a timer to remove warnings as for is filled
	useEffect(() => {
		if (initialSubmit) {
			let timer = setTimeout(() => {
				validateInputs();
			}, 300);
			return () => clearTimeout(timer);
		}
	}, [form]);

	//Clear the success or failure message on a timeout of 5 seconds
	useEffect(() => {
		if (submissionSuccess || submissionFailure) {
			let timer = setTimeout(
				() => {
					//Reset the form
					setForm({
						firstname: '',
						lastname: '',
						email: '',
						practiceArea: 'willstrusts',
					});
					setTermsAccepted(false);
					setResend(false);
					//Show form again
					setSubmissionFailure(false);
					//If success, navigate to the home page
					if (submissionSuccess) {
						setSubmissionSuccess(false);
						navigate('/');
					}
				},
				resend ? 20000 : 7000
			);
			return () => clearTimeout(timer);
		}
	}, [submissionSuccess, submissionFailure]);

	return (
		<div id="potential-new-client" className="page">
			<Container>
				<Row>
					<Col className="mt-4 mb-4">
						{submissionSuccess && (
							<Card id="submission-success-card" className=" shadow-card">
								<Card.Header>
									<Card.Title className="text-center">
										Successfully Submitted
									</Card.Title>
								</Card.Header>
								<Card.Body className="text-center">
									{resend ? (
										<>
											This email address has already been registered. Your email
											verification link has been resent. If you do not receive
											your email verification, please email{' '}
											<a href="mailto:info@sunshinestatelawoffice.com">
												info@sunshinestatelawoffice.com
											</a>{' '}
											or call <a href="tel:1-239-790-4477">1-239-790-4477</a>{' '}
											for assistance.
										</>
									) : (
										<>
											Thank you for completing the Potential New Client
											Application. You will receive an email regarding next
											steps shortly.
										</>
									)}
									<FontAwesomeIcon
										className="mt-3"
										id="submission-success-check"
										icon={faCheckCircle}
									/>
								</Card.Body>
							</Card>
						)}
						{submissionFailure && (
							<Card id="submission-success-card" className="shadow-card">
								<Card.Header>
									<Card.Title className="text-center">
										Submission Error
									</Card.Title>
								</Card.Header>
								<Card.Body className="text-center">
									Unfortunately an error was encountered while saving your
									information. Please try again in a few minutes.
									<FontAwesomeIcon
										className="mt-3"
										id="submission-failure-warning"
										icon={faCircleExclamation}
									/>
								</Card.Body>
							</Card>
						)}
						{!submissionSuccess && !submissionFailure && (
							<>
								<Card className="mb-4 shadow-card">
									<Card.Title className="p-3 pb-0">
										<h1 className="text-center soft-highlight">
											Wills & Trusts
										</h1>
										<hr className="mb-0" />
									</Card.Title>
									<Card.Body className="ps-4 pe-4">
										<p>
											Thank you for inquiring about a Will or Trust from
											Sunshine State Law, PLLC.
										</p>
										<p>
											<strong>Last Will and Testament</strong> – A written
											instrument by which a person directs his or her estate to
											be distributed upon death.
										</p>
										<p>
											<strong>Trust</strong> – A property interest held by one
											person (the trustee) at the request of another (the
											settlor) for the benefit of a third party (the
											beneficiary).
										</p>
									</Card.Body>
								</Card>
								<Card className="shadow-card">
									<Card.Header>
										<Card.Title className="text-center">
											WILLS AND TRUSTS PROSPECTIVE CLIENTS - TERMS AND
											CONDITIONS
										</Card.Title>
									</Card.Header>
									<Card.Body>
										<ol>
											<li>
												Prospective Client. A person who consults with Sunshine
												State Law, PLLC about the possibility of forming a
												client-lawyer relationship with respect to a matter is a
												prospective client.
											</li>
											<li>
												Good Faith. The prospective client must consult Sunshine
												State Law, PLLC in good faith.
											</li>
											<li>
												Conflict of Interest. If the information provided by a
												prospective client indicates that a conflict of interest
												or other reason for non-representation exists, Sunshine
												State Law, PLLC will inform the prospective client or
												decline representation.
											</li>
											<li>
												No client-lawyer relationship. The submission of
												information provided after agreeing to Prospective
												Clients – Terms and Conditions for Estate Planning –
												Wills & Trusts does not automatically form a
												client-lawyer relationship. Sunshine State Law, PLLC has
												no obligation to act on the information provided, draft
												documents pertaining to the information provided,
												communicate with prospective client, or advise or
												consult with the prospective client until a formal
												client-lawyer relationship is formed in writing between
												Sunshine State Law, PLLC and the prospective client.
											</li>
											<li>
												Confidentiality of Information. Sunshine State Law, PLLC
												may not share prospective client information, except as
												permitted by the Rules Regulating the Florida Bar.
												<Link
													to="https://www.floridabar.org/rules/rrtfb/"
													target="_blank"
												>
													Click here
												</Link>{' '}
												for a complete Transcript of the Rules Regulating the
												Florida Bar.
											</li>
											<li>
												Understanding of Prospective Client. Prospective client
												acknowledges and agrees to receiving these clear and
												reasonably understandable warnings and cautionary
												statements that limits Sunshine State Law, PLLC’s
												obligations. If prospective client does not understand,
												do not continue submission of information.
											</li>
										</ol>
									</Card.Body>
									<Card.Footer>
										<Form>
											<Form.Group className="text-center">
												<Form.Check
													inline
													name="accept"
													onChange={(e) => setTermsAccepted(e.target.checked)}
												/>
												<Form.Label>
													I read it. I understand and I agree.
												</Form.Label>
											</Form.Group>
											<Form.Group>
												<Row>
													<Col sm={6}>
														<Form.Label>First Name:</Form.Label>
														<Form.Control
															type="text"
															name="firstname"
															required
															disabled={!termsAccepted}
															onChange={handleForm}
															isInvalid={errors.firstname}
														/>
														{errors.firstname && (
															<Form.Text>{errors.firstname}</Form.Text>
														)}
													</Col>
													<Col sm={6}>
														<Form.Label>Last Name:</Form.Label>
														<Form.Control
															type="text"
															name="lastname"
															required
															disabled={!termsAccepted}
															onChange={handleForm}
															isInvalid={errors.lastname}
														/>
														{errors.lastname && (
															<Form.Text>{errors.lastname}</Form.Text>
														)}
													</Col>
												</Row>
												<Form.Label>Email:</Form.Label>
												<Form.Control
													type="email"
													name="email"
													required
													disabled={!termsAccepted}
													onChange={handleForm}
													isInvalid={errors.email}
												/>
												{errors.email && <Form.Text>{errors.email}</Form.Text>}
												<div className="d-grid mt-2">
													<Button
														variant="primary"
														name="submit"
														disabled={!termsAccepted}
														onClick={handleSubmit}
													>
														Send
													</Button>
												</div>
											</Form.Group>
										</Form>
									</Card.Footer>
								</Card>
							</>
						)}
					</Col>
				</Row>
			</Container>
			{sendingMessage && <LoadingOverlay />}
		</div>
	);
};
export default WillsTrustsPNC;
