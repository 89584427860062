import { Container, Card } from 'react-bootstrap';

const Trusts = () => {
	return (
		<div className="page">
			<Container fluid>
				<Card className="practice-area-card mt-4 shadow-card">
					<Card.Body>
						<Card.Title>
							<h1 className="text-center soft-highlight">Trusts</h1>
							<hr />
						</Card.Title>
						<ul className="mt-4">
							<li>
								Living Revocable Trust -{' '}
								<span className="highlight-text">
									Manage and distribute your property without court interference
								</span>
							</li>
							<li>
								Trustee -{' '}
								<span className="highlight-text">
									Nominate someone you trust and a backup successor trustee
								</span>
							</li>
							<li>
								Land Trusts -{' '}
								<span className="highlight-text">
									Nominate a Trustee to hold your Florida property for the
									benefit of you or another
								</span>
							</li>
							<li>
								Pet Trusts -{' '}
								<span className="highlight-text">
									Arrange and provide for care of your pets after your passing
								</span>
							</li>
						</ul>
					</Card.Body>
				</Card>
			</Container>
		</div>
	);
};
export default Trusts;
