import { Spinner } from 'react-bootstrap';
import { faScaleBalanced } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LoadingOverlay = () => {
	return (
		<div className="loading-overlay">
			<FontAwesomeIcon id="icon" icon={faScaleBalanced} />
		</div>
	);
};
export default LoadingOverlay;
