import { useEffect, useState } from 'react';
import {
	Container,
	Form,
	Card,
	Row,
	Col,
	InputGroup,
	Button,
} from 'react-bootstrap';
import PhoneInput from '../components/formComponents/PhoneInput';
import YesNoRadio from '../components/formComponents/YesNoRadio';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../AxiosConfig';
import CustomStatesDropdown from '../components/formComponents/CustomStatesDropdown/CustomStatesDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from '../utils/functions';
import TimedAlertModal from '../components/TimedAlertModal';

const WillsTrustsPreliminary = () => {
	const navigate = useNavigate();

	const [form, setForm] = useState({
		practiceArea: 'willstrusts',
		name: '',
		address: '',
		city: '',
		state: '',
		zipcode: '',
		phone: '',
		married: false,
		spouse: '',
		allSharedChildren: true,
		numberSharedChildren: 0,
		numberChildrenName: 0,
		numberChildrenSpouse: 0,
		minorChildrenName: false,
		minorChildrenSpouse: false,
		ownsHouse: false,
		houseOwner: '',
		houseValue: '',
		otherRealEstate: false,
		realEstateValue: '',
		ownsRetirementAccounts: false,
		retirementAccountValue: '',
		ownsSavingsCheckingAccounts: false,
		savingCheckingAccountValue: '',
		ownsBusinessEntities: false,
		businessEntitiesValue: '',
		beneficiaryNeeds: 'No',
		relatedDependents: 'No',
		beneficiaryProblems: 'No',
		disinheritIndividuals: 'No',
		expectedInheritence: 'No',
		haveDeceasedChildren: 'No',
	});
	const [initialSubmit, setInitialSubmit] = useState(false); //track first time the submit button is clicked to trigger validation
	const [errors, setErrors] = useState({});
	const [submissionSuccess, setSubmissionSuccess] = useState(false);
	const [submissionFailure, setSubmissionFailure] = useState(false);
	const dollarList = [
		'houseValue',
		'realEstateValue',
		'retirementAccountValue',
		'savingCheckingAccountValue',
		'businessEntitiesValue',
	];
	const [alertModal, setAlertModal] = useState({
		show: false,
		message:
			'Thank you for completing your preliminary information form. One of our lawyers will contact you shortly.',
		title: 'Successfully Submitted',
		handleClose: () => {
			navigate('/');
		},
		navigateTo: '/',
		timeout: 10000,
		icon: 'success',
	});
	//code to associate with previously created PNC
	const { code } = useParams();
	useEffect(() => {
		if (!form.code && code) {
			setForm({ ...form, code: code });
			api
				.post('/verify-link', { code: code })
				.then((res) => {
					if (!res.status === 200) {
						//redirect if not verified
						navigate('/');
					} else {
						setForm({ ...form, code: code, name: res.data.name }); //name is passed forward
					}
				})
				.catch((err) => {
					console.log(err);
					navigate('/');
				});
		}
	}, [code]);

	const handleForm = (e) => {
		if (dollarList.includes(e.target.name)) {
			let num = e.target.value;
			num = num.replace(/\,/g, '');
			const regex = /^\d+\.{0,1}\d{0,2}$/g;
			if (regex.test(num) || e.target.value === '') {
				//numeric only
				setForm({
					...form,
					[e.target.name]: numberWithCommas(e.target.value),
				});
			}
		} else {
			setForm({ ...form, [e.target.name]: e.target.value });
		}
	};

	//Validate Form and submit information
	const handleSubmit = () => {
		setInitialSubmit(true);
		//Grab all label questions
		const labels = document.querySelectorAll(`label.question`);
		let arr = Array.from(labels);
		let submitForm = {
			...form,
			practiceArea: form.practiceArea,
			code: form.code,
		};

		arr.forEach((label) => {
			let question = label.innerText;
			let property = label.getAttribute('for');
			let answer;
			if (dollarList.includes(property)) {
				answer = '$' + form[property];
			} else {
				answer =
					form[property] === true
						? 'Yes'
						: form[property] === false
						? 'No'
						: form[property];
			}
			submitForm = {
				...submitForm,
				[property]: {
					question: question,
					answer: answer,
				},
			};
		});
		if (validateInputs()) {
			//actually submit the form to be saved on the backend
			api
				.post('/preliminary-information', { submitForm: submitForm })
				.then((res) => {
					if (res.status === 200) {
						//saved successfully.
						setAlertModal({ ...alertModal, show: true });
					}
				})
				.catch((err) => {
					console.log(err);
					//TODO: set failure message
					setAlertModal({
						show: true,
						message: 'Unfortunately, your submission could not be saved.',
						title: err.message,
						handleClose: () => {
							navigate('/');
						},
						navigateTo: '/',
						timeout: 10000,
						icon: 'failure',
					});
				});
		} else {
			//Scroll to the top
			window.scroll(0, 0);
		}
	};

	const validateInputs = () => {
		let errors = {};
		if (form.name === '') {
			errors = { ...errors, name: 'Please provide your Full Name.' };
		}
		if (form.address === '') {
			errors = { ...errors, address: 'Please provide your Address.' };
		}
		if (form.city === '') {
			errors = { ...errors, city: 'Please provide your City.' };
		}
		if (form.state === '') {
			errors = {
				...errors,
				state: { message: 'Please provide your State.' },
			};
		}
		if (form.zipcode === '') {
			errors = { ...errors, zipcode: 'Please provide your Zip Code.' };
		}
		if (form.phone === '') {
			errors = { ...errors, phone: true };
		}
		if (form.married && form.spouse === '') {
			errors = { ...errors, spouse: "Please provide your Spouse's Name." };
		}
		if (form.ownsHouse) {
			if (form.houseOwner === '') {
				errors = {
					...errors,
					houseOwner: 'Please select an option for House Owner.',
				};
			}
			if (form.houseValue === 0 || form.houseValue === '') {
				errors = {
					...errors,
					houseValue: 'Please provide the Estimated Home Value.',
				};
			}
		}
		if (
			form.otherRealEstate &&
			(form.realEstateValue === 0 || form.realEstateValue === '')
		) {
			errors = {
				...errors,
				realEstateValue: 'Please provide the Estimated Real Estate Value.',
			};
		}
		if (
			form.ownsRetirementAccounts &&
			(form.retirementAccountValue === 0 || form.retirementAccountValue === '')
		) {
			errors = {
				...errors,
				retirementAccountValue:
					'Please provide the Estimated Value of Retirement and Brokerage Accounts.',
			};
		}
		if (
			form.ownsSavingsCheckingAccounts &&
			(form.savingCheckingAccountValue === 0 ||
				form.savingCheckingAccountValue === '')
		) {
			errors = {
				...errors,
				savingCheckingAccountValue:
					'Please provide the Estimated Value of Accounts.',
			};
		}
		if (
			form.ownsBusinessEntities &&
			(form.businessEntitiesValue === 0 || form.businessEntitiesValue === '')
		) {
			errors = {
				...errors,
				businessEntitiesValue:
					'Please provide the Estimated Value of Business Entities.',
			};
		}

		//update state once
		setErrors(errors);
		return Object.keys(errors).length === 0;
	};

	//Validate inputs on a timer to remove warnings as for is filled
	useEffect(() => {
		if (initialSubmit) {
			let timer = setTimeout(() => {
				validateInputs();
			}, 300);
			return () => clearTimeout(timer);
		}
	}, [form]);

	return (
		<div id="preliminary" className="page">
			<Container fluid>
				<Row>
					<Col className="mt-4 mb-4">
						{submissionSuccess && (
							<Card id="message-success-card">
								<Card.Header>
									<Card.Title className="text-center">
										Successfully Submitted
									</Card.Title>
								</Card.Header>
								<Card.Body className="text-center">
									Thank you for completing your preliminary information form.
									One of our lawyers will contact you shortly.
									<FontAwesomeIcon id="contact-envelope" icon={faEnvelope} />
								</Card.Body>
							</Card>
						)}
						{submissionFailure && (
							<Card>
								<Card.Header>
									<Card.Title className="text-center">
										Failed to Submit
									</Card.Title>
								</Card.Header>
								<Card.Body className="text-center">
									Unfortunately, your submission could not be saved.
									<FontAwesomeIcon
										className="mt-3"
										id="contact-warning"
										icon={faCircleExclamation}
									/>
								</Card.Body>
							</Card>
						)}
						{!submissionSuccess && !submissionFailure && (
							<Card className="shadow-card">
								<Card.Header>
									<Card.Title className="text-center">
										Wills & Trusts Preliminary
									</Card.Title>
								</Card.Header>
								<Card.Body>
									<Form>
										<Form.Group>
											<Form.Label className="question" htmlFor="name">
												Name:
											</Form.Label>
											<Form.Control
												type="text"
												name="name"
												value={form.name}
												onChange={handleForm}
												isInvalid={errors.name}
											/>
											{errors.name && <Form.Text>{errors.name}</Form.Text>}
										</Form.Group>
										<Form.Group>
											<Form.Label className="question" htmlFor="address">
												Address:
											</Form.Label>
											<Form.Control
												type="text"
												name="address"
												value={form.address}
												onChange={handleForm}
												isInvalid={errors.address}
											/>
											{errors.address && (
												<Form.Text>{errors.address}</Form.Text>
											)}
										</Form.Group>
										<Row>
											<Col sm={8}>
												<Form.Group>
													<Form.Label className="question" htmlFor="city">
														City:
													</Form.Label>
													<Form.Control
														type="text"
														name="city"
														value={form.city}
														onChange={handleForm}
														isInvalid={errors.city}
													/>
													{errors.city && <Form.Text>{errors.city}</Form.Text>}
												</Form.Group>
											</Col>
											<Col sm={2}>
												<Form.Group>
													<Form.Label className="question" htmlFor="state">
														State:
													</Form.Label>
													<CustomStatesDropdown
														error={errors.state}
														state={form.state}
														setState={(val) => setForm({ ...form, state: val })}
													/>
												</Form.Group>
											</Col>
											<Col sm={2}>
												<Form.Group>
													<Form.Label className="question" htmlFor="zipcode">
														Zip Code:
													</Form.Label>
													<Form.Control
														type="text"
														name="zipcode"
														value={form.zipcode}
														onChange={handleForm}
														isInvalid={errors.zipcode}
													/>
												</Form.Group>
											</Col>
										</Row>
										<PhoneInput
											labelClass="question"
											isInvalid={errors.phone}
											phone={form.phone}
											setPhone={(val) => setForm({ ...form, phone: val })}
										/>
										<hr />
										<YesNoRadio
											label="Married:"
											labelClass="question"
											name="married"
											setter={(val) => setForm({ ...form, married: val })}
											defaultValue="no"
										/>
										{form.married && (
											<>
												<hr />
												<Form.Group>
													<Form.Label className="question" htmlFor="spouse">
														Spouse Name
													</Form.Label>
													<Form.Control
														type="text"
														name="spouse"
														value={form.spouse}
														onChange={handleForm}
														isInvalid={errors.spouse}
													/>
													{errors.spouse && (
														<Form.Text>{errors.spouse}</Form.Text>
													)}
												</Form.Group>
												<hr />
												<YesNoRadio
													label="Existing Prenuptial Agreement"
													name="existingPrenuptial"
													setter={(val) =>
														setForm({ ...form, existingPrenuptial: val })
													}
													defaultValue="no"
												/>
												<hr />
												<YesNoRadio
													label="Existing Will"
													name="existingWill"
													setter={(val) =>
														setForm({ ...form, existingWill: val })
													}
													defaultValue="no"
												/>
												<hr />
												<YesNoRadio
													label={
														form.name ? (
															form.spouse ? (
																<>
																	Are all children of{' '}
																	<strong>{form.name}</strong> and{' '}
																	<strong>{form.spouse}</strong>?
																</>
															) : (
																<>
																	Are all children of{' '}
																	<strong>{form.name}</strong> and Spouse Name?
																</>
															)
														) : (
															'Are all children of Name and Spouse Name?'
														)
													}
													labelClass="question"
													name="allSharedChildren"
													setter={(val) =>
														setForm({ ...form, allSharedChildren: val })
													}
													defaultValue="yes"
												/>
											</>
										)}

										{form.married && !form.allSharedChildren && (
											<>
												<Form.Group>
													<Form.Label
														className="question"
														htmlFor="numberChildrenName"
													>
														Number of Children of{' '}
														{form.name ? (
															<strong>{form.name}</strong>
														) : (
															'"Name"'
														)}
														:
													</Form.Label>
													<Form.Control
														type="number"
														value={form.numberChildrenName}
														className="ms-1 d-inline"
														style={{ width: '70px' }}
														min={0}
														onChange={(e) =>
															setForm({
																...form,
																numberChildrenName: e.target.value,
															})
														}
													/>
												</Form.Group>
												{form.numberChildrenName > 0 && (
													<YesNoRadio
														label={
															form.name ? (
																<>
																	Are any children of{' '}
																	<strong>{form.name}</strong> a minor? (below
																	18 years of age)
																</>
															) : (
																<>
																	Are any children of Name a minor? (below 18
																	years of age)
																</>
															)
														}
														labelClass="question"
														name="minorChildrenName"
														setter={(val) =>
															setForm({ ...form, minorChildrenName: val })
														}
														defaultValue="no"
													/>
												)}
												<Form.Group>
													<Form.Label
														className="question"
														htmlFor="numberChildrenSpouse"
													>
														Number of Children of{' '}
														{form.name ? (
															<strong>{form.spouse}</strong>
														) : (
															'"Spouse Name"'
														)}
														:
													</Form.Label>
													<Form.Control
														type="number"
														value={form.numberChildrenSpouse}
														className="ms-1 d-inline"
														style={{ width: '70px' }}
														min={0}
														onChange={(e) =>
															setForm({
																...form,
																numberChildrenSpouse: e.target.value,
															})
														}
													/>
												</Form.Group>
												{form.numberChildrenSpouse > 0 && (
													<YesNoRadio
														label={
															form.spouse ? (
																<>
																	Are any children of{' '}
																	<strong>{form.spouse}</strong> a minor? (below
																	18 years of age)
																</>
															) : (
																<>
																	Are any children of Name a minor? (below 18
																	years of age)
																</>
															)
														}
														labelClass="question"
														name="minorChildrenSpouse"
														setter={(val) =>
															setForm({ ...form, minorChildrenSpouse: val })
														}
														defaultValue="no"
													/>
												)}
											</>
										)}
										{/* End married and have separate children */}
										<Form.Group>
											<Form.Label
												className="question"
												htmlFor="numberSharedChildren"
											>
												{form.allSharedChildren
													? 'Number of Children:'
													: 'Number of Shared Children:'}
											</Form.Label>
											<Form.Control
												type="number"
												name="numberSharedChildren"
												value={form.numberSharedChildren}
												className="ms-1 d-inline"
												style={{ width: '70px' }}
												min={0}
												onChange={handleForm}
											/>
										</Form.Group>
										<hr />
										<YesNoRadio
											label={
												form.married ? (
													<>Do you or your Spouse have any deceased children?</>
												) : (
													<>Do you have any deceased children?</>
												)
											}
											labelClass="question"
											name="haveDeceasedChildren"
											setter={(val) =>
												setForm({ ...form, haveDeceasedChildren: val })
											}
											defaultValue="no"
										/>
										<hr />
										<YesNoRadio
											label={
												form.married ? (
													<>Do you or your Spouse own a House?</>
												) : (
													<>Do you own a House?</>
												)
											}
											labelClass="question"
											name="ownsHouse"
											setter={(val) => setForm({ ...form, ownsHouse: val })}
											defaultValue="no"
										/>
										{form.ownsHouse && form.married && (
											<>
												<Form.Label>Who owns the house?</Form.Label>
												<Form.Group>
													<Form.Label>
														{form.name ? form.name : 'You'}:
													</Form.Label>
													<Form.Check
														inline
														type="radio"
														name="houseOwner"
														onChange={(e) =>
															setForm({
																...form,
																houseOwner: form.name ? form.name : 'Name',
															})
														}
														isInvalid={errors.houseOwner}
													/>
													<Form.Label>
														{form.spouse ? form.spouse + ':' : 'Spouse:'}
													</Form.Label>
													<Form.Check
														inline
														type="radio"
														name="houseOwner"
														onChange={(e) =>
															setForm({
																...form,
																houseOwner: form.spouse
																	? form.spouse
																	: 'Spouse',
															})
														}
														isInvalid={errors.houseOwner}
													/>
													<Form.Label>Both:</Form.Label>
													<Form.Check
														inline
														type="radio"
														name="houseOwner"
														onChange={(e) =>
															setForm({ ...form, houseOwner: 'Both' })
														}
														isInvalid={errors.houseOwner}
													/>
													{errors.houseOwner && (
														<Form.Text className="d-inline-block text-start mb-1">
															{errors.houseOwner}
														</Form.Text>
													)}
												</Form.Group>
											</>
										)}
										{form.ownsHouse && (
											<Form.Group className="d-flex align-items-baseline">
												<Form.Label
													className="text-nowrap question"
													htmlFor="houseValue"
												>
													Estimated Value:
												</Form.Label>
												<InputGroup className="ms-2">
													<InputGroup.Text>$</InputGroup.Text>
													<Form.Control
														className="money"
														type="text"
														name="houseValue"
														value={form.houseValue}
														onChange={handleForm}
														isInvalid={errors.houseValue}
													/>
													{errors.houseValue && (
														<Form.Text>{errors.houseValue}</Form.Text>
													)}
												</InputGroup>
											</Form.Group>
										)}
										<hr />
										<YesNoRadio
											label={
												form.married ? (
													<>Do you or your spouse own other real estate?</>
												) : (
													<>Do you own other real estate?</>
												)
											}
											labelClass="question"
											name="otherRealEstate"
											setter={(val) =>
												setForm({ ...form, otherRealEstate: val })
											}
											defaultValue="no"
										/>
										{form.otherRealEstate && (
											<Form.Group className="d-flex align-items-baseline">
												<Form.Label
													className="text-nowrap question"
													htmlFor="realEstateValue"
												>
													Estimated Value:
												</Form.Label>
												<InputGroup className="ms-2">
													<InputGroup.Text>$</InputGroup.Text>
													<Form.Control
														className="money"
														type="text"
														name="realEstateValue"
														value={form.realEstateValue}
														onChange={handleForm}
														isInvalid={errors.realEstateValue}
													/>
													{errors.realEstateValue && (
														<Form.Text>{errors.realEstateValue}</Form.Text>
													)}
												</InputGroup>
											</Form.Group>
										)}
										<hr />
										<YesNoRadio
											label={
												form.married ? (
													<>
														Do you or{' '}
														{form.spouse ? form.spouse : 'your Spouse'} own any
														Retirement or Financial Brokerage Accounts?
													</>
												) : (
													<>
														Do you own any Retirement or Financial Brokerage
														Accounts?
													</>
												)
											}
											labelClass="question"
											name="ownsRetirementAccounts"
											setter={(val) =>
												setForm({ ...form, ownsRetirementAccounts: val })
											}
											defaultValue="no"
										/>
										{form.ownsRetirementAccounts && (
											<Form.Group className="d-flex align-items-baseline">
												<Form.Label
													className="text-nowrap question"
													htmlFor="retirementAccountValue"
												>
													Estimated Value:
												</Form.Label>
												<InputGroup className="ms-2">
													<InputGroup.Text>$</InputGroup.Text>
													<Form.Control
														className="money"
														type="text"
														name="retirementAccountValue"
														value={form.retirementAccountValue}
														onChange={handleForm}
														isInvalid={errors.retirementAccountValue}
													/>
													{errors.retirementAccountValue && (
														<Form.Text>
															{errors.retirementAccountValue}
														</Form.Text>
													)}
												</InputGroup>
											</Form.Group>
										)}
										<hr />
										<YesNoRadio
											label={
												form.married ? (
													<>
														Do you or{' '}
														{form.spouse ? form.spouse : 'your Spouse'} own any
														Savings Accounts, Checking Accounts, and/or CDs?
													</>
												) : (
													<>
														Do you own any Savings Accounts, Checking Accounts,
														and/or CDs?
													</>
												)
											}
											labelClass="question"
											name="ownsSavingsCheckingAccounts"
											setter={(val) =>
												setForm({ ...form, ownsSavingsCheckingAccounts: val })
											}
											defaultValue="no"
										/>
										{form.ownsSavingsCheckingAccounts && (
											<Form.Group className="d-flex align-items-baseline">
												<Form.Label
													className="text-nowrap question"
													htmlFor="savingCheckingAccountValue"
												>
													Estimated Value:
												</Form.Label>
												<InputGroup className="ms-2">
													<InputGroup.Text>$</InputGroup.Text>
													<Form.Control
														className="money"
														type="text"
														name="savingCheckingAccountValue"
														value={form.savingCheckingAccountValue}
														onChange={handleForm}
														isInvalid={errors.savingCheckingAccountValue}
													/>
													{errors.savingCheckingAccountValue && (
														<Form.Text>
															{errors.savingCheckingAccountValue}
														</Form.Text>
													)}
												</InputGroup>
											</Form.Group>
										)}
										<hr />
										<YesNoRadio
											label={
												form.married ? (
													<>
														Do you or{' '}
														{form.spouse ? form.spouse : 'your Spouse'} own any
														Business Entities such as LLCs, Corporations or
														Partnerships?
													</>
												) : (
													<>
														Do you or your spouse own any Business Entities such
														as LLCs, Corporations or Partnerships?
													</>
												)
											}
											labelClass="question"
											name="ownsBusinessEntities"
											setter={(val) =>
												setForm({ ...form, ownsBusinessEntities: val })
											}
											defaultValue="no"
										/>

										{form.ownsBusinessEntities && (
											<Form.Group className="d-flex align-items-baseline">
												<Form.Label
													className="text-nowrap question"
													htmlFor="businessEntitiesValue"
												>
													Estimated Value:
												</Form.Label>
												<InputGroup className="ms-2">
													<InputGroup.Text>$</InputGroup.Text>
													<Form.Control
														className="money"
														type="text"
														name="businessEntitiesValue"
														value={form.businessEntitiesValue}
														onChange={handleForm}
														isInvalid={errors.businessEntitiesValue}
													/>
													{errors.businessEntitiesValue && (
														<Form.Text>
															{errors.businessEntitiesValue}
														</Form.Text>
													)}
												</InputGroup>
											</Form.Group>
										)}
										<hr />
										<YesNoRadio
											label={
												<>
													Do any of your beneficiaries have a learning
													disability, special educational, medical or physical
													needs?
												</>
											}
											labelClass="question"
											name="beneficiaryNeeds"
											setter={(val) =>
												setForm({ ...form, beneficiaryNeeds: val })
											}
											defaultValue="no"
										/>
										<hr />
										<YesNoRadio
											label={
												<>
													Do you have any other relatives (other than children)
													who depend on you for all or part of their support?
												</>
											}
											labelClass="question"
											name="relatedDependents"
											setter={(val) =>
												setForm({ ...form, relatedDependents: val })
											}
											defaultValue="no"
										/>
										<hr />
										<YesNoRadio
											label={
												<>
													Do you think any of your beneficiaries have special
													problems with spouses, drugs, alcohol or handling
													money?
												</>
											}
											labelClass="question"
											name="beneficiaryProblems"
											setter={(val) =>
												setForm({ ...form, beneficiaryProblems: val })
											}
											defaultValue="no"
										/>
										<hr />
										<YesNoRadio
											label={
												<>
													Do you wish to disinherit any of your children,
													grandchildren or any other close relative?
												</>
											}
											labelClass="question"
											name="disinheritIndividuals"
											setter={(val) =>
												setForm({ ...form, disinheritIndividuals: val })
											}
											defaultValue="no"
										/>
										<hr />
										<Form.Label
											className="question"
											htmlFor="expectedInheritence"
										>
											Do you expect to inherit substantial assets ($100,000+)
											within 1 year?
										</Form.Label>
										<Form.Group>
											<Form.Label>Yes:</Form.Label>
											<Form.Check
												inline
												type="radio"
												name="expectedInheritence"
												value="Yes"
												onChange={(e) =>
													setForm({
														...form,
														expectedInheritence: e.target.value,
													})
												}
											/>{' '}
											<Form.Label>No:</Form.Label>
											<Form.Check
												inline
												type="radio"
												name="expectedInheritence"
												value="No"
												onChange={(e) =>
													setForm({
														...form,
														expectedInheritence: e.target.value,
													})
												}
												defaultChecked={true}
											/>{' '}
											<Form.Label>Maybe:</Form.Label>
											<Form.Check
												inline
												type="radio"
												name="expectedInheritence"
												value="Maybe"
												onChange={(e) =>
													setForm({
														...form,
														expectedInheritence: e.target.value,
													})
												}
											/>
										</Form.Group>
										<div className="d-grid mt-2">
											<Button variant="primary" onClick={handleSubmit}>
												Submit
											</Button>
										</div>
									</Form>
								</Card.Body>
							</Card>
						)}
					</Col>
				</Row>
			</Container>
			<TimedAlertModal
				show={alertModal.show}
				message={alertModal.message}
				title={alertModal.title}
				handleClose={alertModal.handleClose}
				navigateTo={alertModal.navigateTo}
				timeout={alertModal.timeout}
				icon={alertModal.icon}
			/>
		</div>
	);
};
export default WillsTrustsPreliminary;
