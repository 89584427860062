import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Team from './pages/Team';
import Wills from './pages/Wills';
import Trusts from './pages/Trusts';
import Probate from './pages/Probate';
import Footer from './components/Footer';
import NavigationBar from './components/NavigationBar';
import Questionnaire from './pages/Questionnaire';
import Contact from './pages/Contact';
import WillsTrustsPNC from './pages/WillsTrustsPNC';
import WillsTrustsPreliminary from './pages/WillsTrustsPreliminary';
import ProbatePNC from './pages/ProbatePNC';
import ProbatePreliminary from './pages/ProbatePreliminary';
import AdminPNC from './pages/AdminPNC';

function App() {
	return (
		<BrowserRouter>
			<NavigationBar />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/about" element={<About />} />
				<Route path="/team" element={<Team />} />
				<Route path="/wills" element={<Wills />} />
				<Route path="/trusts" element={<Trusts />} />
				<Route path="/probate" element={<Probate />} />
				<Route path="/questionnaire" element={<Questionnaire />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="/wills-trusts-pnc" element={<WillsTrustsPNC />} />
				<Route path="/probate-pnc" element={<ProbatePNC />} />
				<Route
					path="/wills-trusts-preliminary/:code"
					element={<WillsTrustsPreliminary />}
				/>
				<Route
					path="/probate-preliminary/:code"
					element={<ProbatePreliminary />}
				/>
				<Route path="/admin-pnc" element={<AdminPNC />} />
			</Routes>
			<Footer />
		</BrowserRouter>
	);
}

export default App;
