//Custom Dropdown to allow display of abbreviation and state name

import { Dropdown, Form } from 'react-bootstrap';
import { UsaStates } from 'usa-states';
import './style.scss';

const CustomStatesDropdown = ({ state, setState, error }) => {
	const { states } = new UsaStates();

	return (
		<>
			<Dropdown
				className="customStatesDropdown"
				onSelect={(val) => setState(val)}
			>
				<Dropdown.Toggle variant="outline" className="form-control p-0">
					<Form.Group>
						<Form.Control
							style={{ cursor: 'pointer' }}
							readOnly
							value={state}
							isInvalid={error}
						/>
						{error && (
							<Form.Text className="text-wrap">{error?.message}</Form.Text>
						)}
					</Form.Group>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{states.map((state, i) => {
						return (
							<Dropdown.Item key={i} eventKey={state.abbreviation}>
								{state.abbreviation} - {state.name}
							</Dropdown.Item>
						);
					})}
				</Dropdown.Menu>
			</Dropdown>
		</>
	);
};
export default CustomStatesDropdown;
