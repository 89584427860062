import { useEffect, useState } from 'react';
import {
	Container,
	Form,
	Card,
	Row,
	Col,
	Dropdown,
	DropdownButton,
	Button,
} from 'react-bootstrap';
import PhoneInput from '../components/formComponents/PhoneInput';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../AxiosConfig';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CustomStatesDropdown from '../components/formComponents/CustomStatesDropdown/CustomStatesDropdown';
import TimedAlertModal from '../components/TimedAlertModal';

const ProbatePreliminary = () => {
	const navigate = useNavigate();

	const [alertModal, setAlertModal] = useState({
		show: false,
		message:
			'Thank you for completing your preliminary information form. One of our lawyers will contact you shortly.',
		title: 'Successfully Submitted',
		handleClose: () => {
			navigate('/');
		},
		navigateTo: '/',
		timeout: 10000,
		icon: 'success',
	});

	const [form, setForm] = useState({
		practiceArea: 'probate',
		code: '',
		name: '',
		address: '',
		city: '',
		state: '',
		zipcode: '',
		phone: '',
		deceased: '',
		dateOfDeath: '',
		deceasedState: '',
		relationshipToDeceased: '',
		deathCertificate: 'No',
		hasWill: 'No',
		hasTrust: 'No',
		assets: {
			house: { text: 'House', value: false },
			car: { text: 'Car', value: false },
			bankAccount: { text: 'Bank Account', value: false },
			financialAccount: { text: 'Financial Account', value: false },
			property: { text: 'Property', value: false },
			lifeInsurance: { text: 'Life Insurance', value: false },
			other: { text: 'Other Assets', value: false },
		},
		listedAssets: '',
		otherListedAssets: '',
		estimatedValueAssets: '',
	});
	const [showDateOfDeathCalendar, setShowDateOfDeathCalendar] = useState(false);
	const [initialSubmit, setInitialSubmit] = useState(false); //track first time the submit button is clicked to trigger validation
	const [errors, setErrors] = useState({});
	//code to associate with previously created PNC
	const { code } = useParams();

	// Set up EventListener for calendar to close when outside clicks happen
	useEffect(() => {
		document.addEventListener('click', (e) => {
			let className = e.target.className;
			let parentClassName = e.target.parentElement.className;
			if (
				!className.match(/react-calendar/g) &&
				!parentClassName.match(/react-calendar/g)
			) {
				setShowDateOfDeathCalendar(false);
			}
		});
		return () => {
			document.removeEventListener('click', () => {});
		};
	}, []);

	//Enumerate assets on change of asset checkboxes
	useEffect(() => {
		let props = Object.keys(form.assets);
		let list = '';
		props.forEach((prop) => {
			if (form.assets[prop].value) {
				console.log({ prop });
				list +=
					list !== '' ? `, ${form.assets[prop].text}` : form.assets[prop].text;
			}
		});
		setForm({ ...form, listedAssets: list });
	}, [form.assets]);

	//Verify the user's code on render
	useEffect(() => {
		if (!form.code && code) {
			api
				.post('/verify-link', { code: code })
				.then((res) => {
					if (!res.status === 200) {
						//redirect if not verified
						navigate('/');
					} else {
						setForm({ ...form, code: code, name: res.data.name }); //name is passed forward
					}
				})
				.catch((err) => {
					console.log(err);
					navigate('/');
				});
		}
	}, [code]);

	const handleForm = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};

	const handleDateOfDeathCalendar = (date) => {
		setForm({
			...form,
			dateOfDeath: date.toLocaleDateString('en-us', {
				// weekday: 'long',
				year: 'numeric',
				month: 'short',
				day: 'numeric',
			}),
		});
		//close Calendar
		setShowDateOfDeathCalendar(false);
	};

	//Validate Form and submit information
	const handleSubmit = () => {
		setInitialSubmit(true);
		//Grab all label questions
		const labels = document.querySelectorAll(`label.question`);
		let arr = Array.from(labels);
		let submitForm = {
			practiceArea: form.practiceArea,
			code: form.code,
			listedAssets: form.listedAssets,
		};

		arr.forEach((label) => {
			let question = label.innerText;
			let property = label.getAttribute('for');

			submitForm = {
				...submitForm,
				[property]: { question: question, answer: form[property] },
			};
		});
		if (validateInputs()) {
			// console.log({ submitForm }); //This is the serialized form to send to server
			//actually submit the form to be saved on the backend
			api
				.post('/preliminary-information', { submitForm, submitForm })
				.then((res) => {
					if (res.status === 200) {
						//saved successfully.
						setAlertModal({ ...alertModal, show: true });
					}
				})
				.catch((err) => {
					console.log(err);
					setAlertModal({
						show: true,
						message: 'Unfortunately, your submission could not be saved.',
						title: err.message,
						handleClose: () => {
							navigate('/');
						},
						navigateTo: '/',
						timeout: 10000,
						icon: 'failure',
					});
				});
		} else {
			//Scroll to the top
			window.scroll(0, 0);
		}
	};

	const validateInputs = () => {
		let errors = {};
		if (form.name === '') {
			errors = { ...errors, name: true };
		}
		if (form.address === '') {
			errors = { ...errors, address: true };
		}
		if (form.city === '') {
			errors = { ...errors, city: true };
		}
		if (form.state === '') {
			errors = {
				...errors,
				state: { message: 'Please provide your State.' },
			};
		}
		if (form.zipcode === '') {
			errors = { ...errors, zipcode: true };
		}
		if (form.phone === '') {
			errors = { ...errors, phone: true };
		}
		if (form.deceased === '') {
			errors = { ...errors, deceased: true };
		}
		if (form.dateOfDeath === '') {
			errors = { ...errors, dateOfDeath: true };
		}
		if (form.deceasedState === '') {
			errors = { ...errors, deceasedState: true };
		}
		if (form.relationshipToDeceased === '') {
			errors = { ...errors, relationshipToDeceased: true };
		}
		if (form.assets.other && form.otherListedAssets === '') {
			errors = { ...errors, otherListedAssets: true };
		}
		if (form.estimatedValueAssets === '') {
			errors = { ...errors, estimatedValueAssets: true };
		}
		//update state once
		setErrors(errors);
		return Object.keys(errors).length === 0;
	};

	//Validate inputs on a timer to remove warnings as for is filled
	useEffect(() => {
		if (initialSubmit) {
			let timer = setTimeout(() => {
				validateInputs();
			}, 300);
			return () => clearTimeout(timer);
		}
	}, [form]);

	return (
		<div id="preliminary" className="page">
			<Container fluid>
				<Row>
					<Col className="mt-4 mb-4">
						<Card className="shadow-card">
							<Card.Header>
								<Card.Title className="text-center">
									Probate Preliminary
								</Card.Title>
							</Card.Header>
							<Card.Body>
								{Object.keys(errors).length > 0 && (
									<h5 className="text-danger">
										*Please complete all required fields.
									</h5>
								)}
								<Form>
									<Form.Group>
										<Form.Label className="question" htmlFor="name">
											Name:
										</Form.Label>
										<Form.Control
											type="text"
											name="name"
											value={form.name}
											onChange={handleForm}
											isInvalid={errors.name}
										/>
										{errors.name && (
											<Form.Text>Please provide your Full Name.</Form.Text>
										)}
									</Form.Group>
									<Form.Group>
										<Form.Label className="question" htmlFor="address">
											Address:
										</Form.Label>
										<Form.Control
											type="text"
											name="address"
											value={form.address}
											onChange={handleForm}
											isInvalid={errors.address}
										/>
										{errors.address && (
											<Form.Text>Please provide your Address.</Form.Text>
										)}
									</Form.Group>
									<Row>
										<Col sm={8}>
											<Form.Group>
												<Form.Label className="question" htmlFor="city">
													City:
												</Form.Label>
												<Form.Control
													type="text"
													name="city"
													value={form.city}
													onChange={handleForm}
													isInvalid={errors.city}
												/>
												{errors.city && (
													<Form.Text>Please provide your City.</Form.Text>
												)}
											</Form.Group>
										</Col>
										<Col sm={2}>
											<Form.Group>
												<Form.Label className="question" htmlFor="state">
													State:
												</Form.Label>
												<CustomStatesDropdown
													state={form.state}
													setState={(val) => setForm({ ...form, state: val })}
													error={errors.state}
												/>
											</Form.Group>
										</Col>
										<Col sm={2}>
											<Form.Group>
												<Form.Label className="question" htmlFor="zipcode">
													Zip Code:
												</Form.Label>
												<Form.Control
													type="text"
													name="zipcode"
													value={form.zipcode}
													onChange={handleForm}
													isInvalid={errors.zipcode}
												/>
												{errors.zipcode && (
													<Form.Text>Please provide your Zip Code.</Form.Text>
												)}
											</Form.Group>
										</Col>
									</Row>
									<PhoneInput
										labelClass="question"
										phone={form.phone}
										setPhone={(val) => setForm({ ...form, phone: val })}
										isInvalid={errors.phone}
									/>
									<hr />
									<Form.Label className="question" htmlFor="deceased">
										Who Died?
									</Form.Label>
									<Form.Group>
										<Form.Label>Name:</Form.Label>
										<Form.Control
											type="text"
											name="deceased"
											value={form.deceased}
											onChange={(e) =>
												setForm({ ...form, deceased: e.target.value })
											}
											isInvalid={errors.deceased}
										/>
										{errors.deceased && (
											<Form.Text>
												Please provide the Name of the deceased.
											</Form.Text>
										)}
									</Form.Group>
									<hr />
									<Form.Group>
										<Form.Label className="question" htmlFor="dateOfDeath">
											When did{' '}
											{form.deceased ? (
												<strong>{form.deceased}</strong>
											) : (
												'Dead Name'
											)}{' '}
											die?
										</Form.Label>
										<Form.Control
											name="dateOfDeath"
											className="react-calendar-input"
											type="text"
											value={
												form.dateOfDeath
													? new Date(form.dateOfDeath).toLocaleDateString(
															'en-us',
															{
																// weekday: 'long',
																year: 'numeric',
																month: 'short',
																day: 'numeric',
															}
													  )
													: ''
											}
											onFocus={() => setShowDateOfDeathCalendar(true)}
											readOnly
											isInvalid={errors.dateOfDeath}
										/>
										{showDateOfDeathCalendar && (
											<Calendar
												onChange={handleDateOfDeathCalendar}
												style={{ position: 'absolute' }}
												value={form.dateOfDeath}
												maxDate={new Date()}
											/>
										)}
										{errors.dateOfDeath && (
											<Form.Text>Please provide the Date of Death.</Form.Text>
										)}
									</Form.Group>
									<hr />
									<Form.Group>
										<Form.Label className="question" htmlFor="deceasedState">
											What State is{' '}
											{form.deceased ? (
												<strong>{form.deceased}</strong>
											) : (
												'Dead Name'
											)}{' '}
											from?
										</Form.Label>
										<CustomStatesDropdown
											state={form.deceasedState}
											setState={(val) =>
												setForm({ ...form, deceasedState: val })
											}
											error={errors.deceasedState}
										/>
										{errors.deceasedState && (
											<Form.Text>
												Please provide the State that the Deceased is from.
											</Form.Text>
										)}
									</Form.Group>
									<hr />
									<Form.Group>
										<Form.Label
											className="question"
											htmlFor="relationshipToDeceased"
										>
											What is your relationship to{' '}
											{form.deceased ? (
												<strong>{form.deceased}</strong>
											) : (
												'Dead Name'
											)}
											?
										</Form.Label>
										<Form.Select
											name="relationshipToDeceased"
											onChange={(e) =>
												setForm({
													...form,
													relationshipToDeceased: e.target.value,
												})
											}
											isInvalid={errors.relationshipToDeceased}
											defaultValue="initial"
										>
											<option disabled value="initial">
												- Choose Relationship -
											</option>
											<option value="Spouse">Spouse</option>
											<option value="Son">Son</option>
											<option value="Daughter">Daughter</option>
											<option value="Mother">Mother</option>
											<option value="Father">Father</option>
											<option value="Brother">Brother</option>
											<option value="Sister">Sister</option>
											<option value="Uncle">Uncle</option>
											<option value="Aunt">Aunt</option>
											<option value="Other">Other</option>
										</Form.Select>
										{errors.relationshipToDeceased && (
											<Form.Text>
												Please select your Relationship to the Deceased.
											</Form.Text>
										)}
									</Form.Group>
									<hr />
									<Form.Label className="question" htmlFor="hasWill">
										Did{' '}
										{form.deceased ? (
											<strong>{form.deceased}</strong>
										) : (
											'Dead Name'
										)}{' '}
										have a last will and testament?
									</Form.Label>
									<Form.Group>
										<Form.Label>Yes:</Form.Label>
										<Form.Check
											inline
											type="radio"
											name="hasWill"
											value="Yes"
											onChange={(e) =>
												setForm({ ...form, hasWill: e.target.value })
											}
										/>
										<Form.Label>No:</Form.Label>
										<Form.Check
											inline
											type="radio"
											name="hasWill"
											value="No"
											onChange={(e) =>
												setForm({ ...form, hasWill: e.target.value })
											}
											defaultChecked={true}
										/>
										<Form.Label>Unknown:</Form.Label>
										<Form.Check
											inline
											type="radio"
											name="hasWill"
											value="Unknown"
											onChange={(e) =>
												setForm({ ...form, hasWill: e.target.value })
											}
										/>
									</Form.Group>
									<hr />
									<Form.Label className="question" htmlFor="hasTrust">
										Did{' '}
										{form.deceased ? (
											<strong>{form.deceased}</strong>
										) : (
											'Dead Name'
										)}{' '}
										have a trust?
									</Form.Label>
									<Form.Group>
										<Form.Label>Yes:</Form.Label>
										<Form.Check
											inline
											type="radio"
											name="hasTrust"
											value="Yes"
											onChange={(e) =>
												setForm({ ...form, hasTrust: e.target.value })
											}
										/>
										<Form.Label>No:</Form.Label>
										<Form.Check
											inline
											type="radio"
											name="hasTrust"
											value="No"
											onChange={(e) =>
												setForm({ ...form, hasTrust: e.target.value })
											}
											defaultChecked={true}
										/>
										<Form.Label>Unknown:</Form.Label>
										<Form.Check
											inline
											type="radio"
											name="hasTrust"
											value="Unknown"
											onChange={(e) =>
												setForm({ ...form, hasTrust: e.target.value })
											}
										/>
									</Form.Group>
									<hr />
									<Form.Label className="question" htmlFor="deathCertificate">
										Do you have a death certificate of{' '}
										{form.deceased ? (
											<strong>{form.deceased}</strong>
										) : (
											'Dead Name'
										)}{' '}
										?
									</Form.Label>
									<Form.Group>
										<Form.Label>Yes:</Form.Label>
										<Form.Check
											inline
											type="radio"
											name="deathCertificate"
											value="Yes"
											onChange={(e) =>
												setForm({ ...form, deathCertificate: e.target.value })
											}
										/>
										<Form.Label>No:</Form.Label>
										<Form.Check
											inline
											type="radio"
											name="deathCertificate"
											value="No"
											onChange={(e) =>
												setForm({ ...form, deathCertificate: e.target.value })
											}
											defaultChecked={true}
										/>
										<Form.Label>Waiting:</Form.Label>
										<Form.Check
											inline
											type="radio"
											name="deathCertificate"
											value="Waiting"
											onChange={(e) =>
												setForm({ ...form, deathCertificate: e.target.value })
											}
										/>
									</Form.Group>
									<hr />
									<Form.Label className="question" htmlFor="assets">
										Choose all assets of{' '}
										{form.deceased ? (
											<strong>{form.deceased}</strong>
										) : (
											'Dead Name'
										)}
										:
									</Form.Label>

									<Form.Group>
										{Object.values(form.assets).map((asset, i) => {
											return (
												<div className="d-inline-block" key={i}>
													<Form.Label>{asset.text}:</Form.Label>
													<Form.Check
														inline
														type="checkbox"
														name={Object.keys(form.assets)[i]}
														checked={form.assets[asset.value]}
														onChange={(e) => {
															setForm({
																...form,
																assets: {
																	...form.assets,
																	[e.target.name]: {
																		...form.assets[e.target.name],
																		value: e.target.checked,
																	},
																},
															});
														}}
													/>
												</div>
											);
										})}
									</Form.Group>
									{form.assets.other.value && (
										<Form.Group>
											<Form.Label htmlFor="otherListedAssets">
												Please list other assets:
											</Form.Label>
											<Form.Control
												type="text"
												name="otherListedAssets"
												value={form.otherListedAssets}
												onChange={handleForm}
												isInvalid={errors.otherListedAssets}
											/>
											{errors.otherListedAssets && (
												<Form.Text>Please list Other Assets.</Form.Text>
											)}
										</Form.Group>
									)}
									<hr />
									<Form.Group>
										<Form.Label
											className="question"
											htmlFor="estimatedValueAssets"
										>
											Estimated Value of all assets of{' '}
											{form.deceased ? (
												<strong>{form.deceased}</strong>
											) : (
												'Dead Name'
											)}
											:
										</Form.Label>
										<Form.Select
											name="estimatedValueAssets"
											onChange={(e) =>
												setForm({
													...form,
													estimatedValueAssets: e.target.value,
												})
											}
											isInvalid={errors.estimatedValueAssets}
											defaultValue="initial"
										>
											<option disabled value="initial">
												- Choose Value -
											</option>
											<option value="$0 - $40,000">$0 - $40,000</option>
											<option value="$40,001 - $70,000">
												$40,001 - $70,000
											</option>
											<option value="$70,001 - $100,000">
												$70,001 - $100,000
											</option>
											<option value="$100,001 - $1,000,000">
												$100,001 - $1,000,000
											</option>
											<option value="+$1,000,000">+$1,000,000</option>
										</Form.Select>
										{errors.estimatedValueAssets && (
											<Form.Text>
												Please choose and Estimate Value of assets.
											</Form.Text>
										)}
									</Form.Group>
									<div className="d-grid mt-2">
										<Button variant="primary" onClick={handleSubmit}>
											Submit
										</Button>
									</div>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
			<TimedAlertModal
				show={alertModal.show}
				message={alertModal.message}
				title={alertModal.title}
				handleClose={alertModal.handleClose}
				navigateTo={alertModal.navigateTo}
				timeout={alertModal.timeout}
				icon={alertModal.icon}
			/>
		</div>
	);
};
export default ProbatePreliminary;
