//Component to use as a self formating phone number input

import { useState } from 'react';
import { Form } from 'react-bootstrap';

const PhoneInput = ({
	phone,
	setPhone,
	isInvalid,
	formText = '',
	labelClass,
}) => {
	const handlePhone = (e) => {
		let number = e.target.value;
		const regex = /-/g;
		const numReg = /[0-9]/;
		number = number.replace(regex, ''); //remove added hyphens
		const digits = number.split('');
		let newFormat = '';
		if (digits.length <= 10) {
			digits.forEach((digit, index) => {
				if (digit.match(numReg)) {
					//Add hyphens
					if (index === 3 || index === 6) {
						newFormat = newFormat.concat('-');
					}
					newFormat = newFormat.concat(digit);
				}
			});
			setPhone(newFormat);
		}
	};
	return (
		<Form.Group>
			<Form.Label htmlFor="phone" className={labelClass}>
				Phone:
			</Form.Label>
			<Form.Control
				required
				type="tel"
				name="phone"
				value={phone}
				onChange={handlePhone}
				isInvalid={isInvalid}
			/>
			{isInvalid && (
				<Form.Text>{formText || 'Please provide your Phone Number.'}</Form.Text>
			)}
		</Form.Group>
	);
};
export default PhoneInput;
