import { useState } from 'react';
import { Col, Container, Form, Row, Card } from 'react-bootstrap';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const Questionnaire = () => {
	const [form, setForm] = useState({
		//Husband Form
		fullnameHusband: '',
		husbandDOB: '',
		husbandSSN: '',
		husbandIsCitizen: '',
		//Wife Form
		husbandNationality: '',
		fullnameWife: '',
		wifeDOB: '',
		wifeSSN: '',
		wifeIsCitizen: '',
		//Address
		address: '',
		city: '',
		state: '',
		zip: '',
		county: '',
		homePhone: '',
		cellPhone: '',
		email: '',
	});

	const [showHusbandCalendar, setShowHusbandCalendar] = useState(false);
	const [showWifeCalendar, setShowWifeCalendar] = useState(false);

	const formChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};

	//function to handle and modify SSN
	const handleSSN = (e) => {
		let regex = /^[0-9]{1,10}$/; //only numbers allowed, 9 digits

		if (e.target.value.match(regex) || e.target.value === '') {
			setForm({ ...form, [e.target.name]: e.target.value });
		}
	};

	const handleHusbandCalendar = (date) => {
		setForm({ ...form, husbandDOB: date.toString() });
		//close Calendar
		setShowHusbandCalendar(false);
	};
	const handleWifeCalendar = (date) => {
		setForm({ ...form, wifeDOB: date.toString() });
		//close Calendar
		setShowWifeCalendar(false);
	};

	return (
		<div className="page">
			<Container id="questionnaire" fluid>
				<h1 className="text-center">Married Preliminary Information Sheet</h1>
				<Row>
					<Col sm={{ span: 6, offset: 3 }}>
						<Form>
							<Card>
								<Card.Body>
									<Form.Group>
										<Form.Label>Full Name Husband:</Form.Label>
										<Form.Control
											type="text"
											name="fullnameHusband"
											value={form.fullnameHusband}
											onChange={formChange}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label>Date of Birth:</Form.Label>
										<Form.Control
											type="text"
											value={
												form.husbandDOB
													? new Date(form.husbandDOB).toLocaleDateString(
															'en-us',
															{
																// weekday: 'long',
																year: 'numeric',
																month: 'short',
																day: 'numeric',
															}
													  )
													: ''
											}
											onFocus={setShowHusbandCalendar}
											readOnly
										/>
										{showHusbandCalendar && (
											<Calendar
												onChange={handleHusbandCalendar}
												style={{ position: 'absolute' }}
												value={form.husbandDOB}
												maxDate={new Date()}
											/>
										)}
									</Form.Group>
									<Form.Group>
										<Form.Label>Social Security Number:</Form.Label>
										<Form.Control
											type="text"
											name="husbandSSN"
											value={form.husbandSSN}
											onChange={handleSSN}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label className="me-4">U.S. Citizen?</Form.Label>
										<Form.Check
											inline
											type="radio"
											name="husbandIsCitizen"
											value={form.husbandIsCitizen}
											label="Yes"
											onChange={(e) => {
												setForm({
													...form,
													husbandIsCitizen: true,
													husbandNationality: '',
												});
											}}
											checked={form.husbandIsCitizen}
										/>
										<Form.Check
											inline
											type="radio"
											name="husbandIsCitizen"
											value={form.husbandIsCitizen}
											label="No"
											onChange={(e) =>
												setForm({ ...form, husbandIsCitizen: false })
											}
											checked={!form.husbandIsCitizen}
										/>
									</Form.Group>
									{!form.husbandIsCitizen && (
										<Form.Group>
											<Form.Label>If no, what nationality?</Form.Label>
											<Form.Control
												type="text"
												name="husbandNationality"
												value={form.husbandNationality}
												onChange={formChange}
											/>
										</Form.Group>
									)}
								</Card.Body>
							</Card>
							<Card>
								<Card.Body>
									<Form.Group>
										<Form.Label>Full Name Wife:</Form.Label>
										<Form.Control
											type="text"
											name="fullnameWife"
											value={form.fullnameWife}
											onChange={formChange}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label>Date of Birth:</Form.Label>
										<Form.Control
											type="text"
											value={
												form.wifeDOB
													? new Date(form.wifeDOB).toLocaleDateString('en-us', {
															// weekday: 'long',
															year: 'numeric',
															month: 'short',
															day: 'numeric',
													  })
													: ''
											}
											onFocus={setShowWifeCalendar}
											readOnly
										/>
										{showWifeCalendar && (
											<Calendar
												onChange={handleWifeCalendar}
												style={{ position: 'absolute' }}
												value={form.wifeDOB}
												maxDate={new Date()}
											/>
										)}
									</Form.Group>
									<Form.Group>
										<Form.Label>Social Security Number:</Form.Label>
										<Form.Control
											type="text"
											name="wifeSSN"
											value={form.wifeSSN}
											onChange={handleSSN}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label className="me-4">U.S. Citizen?</Form.Label>
										<Form.Check
											inline
											type="radio"
											name="wifeIsCitizen"
											value={form.wifeIsCitizen}
											label="Yes"
											onChange={(e) => {
												setForm({
													...form,
													wifeIsCitizen: true,
													wifeNationality: '',
												});
											}}
											checked={form.wifeIsCitizen}
										/>
										<Form.Check
											inline
											type="radio"
											name="wifeIsCitizen"
											value={form.wifeIsCitizen}
											label="No"
											onChange={(e) =>
												setForm({ ...form, wifeIsCitizen: false })
											}
											checked={!form.wifeIsCitizen}
										/>
									</Form.Group>
									{!form.wifeIsCitizen && (
										<Form.Group>
											<Form.Label>If no, what nationality?</Form.Label>
											<Form.Control
												type="text"
												name="wifeNationality"
												value={form.wifeNationality}
												onChange={formChange}
											/>
										</Form.Group>
									)}
								</Card.Body>
							</Card>
							<Card>
								<Card.Body>
									<Form.Group>
										<Form.Label>Home Address:</Form.Label>
										<Form.Control
											type="text"
											name="address"
											value={form.address}
											onChange={formChange}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label>City:</Form.Label>
										<Form.Control
											type="text"
											name="city"
											value={form.city}
											onChange={formChange}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label>State:</Form.Label>
										<Form.Control
											type="text"
											name="state"
											value={form.state}
											onChange={formChange}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label>Zip:</Form.Label>
										<Form.Control
											type="text"
											name="zip"
											value={form.zip}
											onChange={formChange}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label>County:</Form.Label>
										<Form.Control
											type="text"
											name="county"
											value={form.county}
											onChange={formChange}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label>Home Phone:</Form.Label>
										<Form.Control
											type="text"
											name="homePhone"
											value={form.homePhone}
											onChange={formChange}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label>Cell Phone:</Form.Label>
										<Form.Control
											type="text"
											name="cellPhone"
											value={form.cellPhone}
											onChange={formChange}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label>Email:</Form.Label>
										<Form.Control
											type="text"
											name="email"
											value={form.email}
											onChange={formChange}
										/>
									</Form.Group>
								</Card.Body>
							</Card>
						</Form>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
export default Questionnaire;
