import { Container, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPhoneFlip } from '@fortawesome/free-solid-svg-icons';

const About = () => {
	return (
		<div className="page">
			<Container fluid>
				<Card className="practice-area-card mt-4 shadow-card">
					<Card.Body>
						<Card.Title>
							<h1 className="text-center soft-highlight">About Us</h1>
							<hr />
						</Card.Title>
						<p>
							Sunshine State Law's purpose is to serve Southwest Florida's
							growing need for a less traditional, more approachable law firm
							that focuses on innovation and client centered services in the
							legal fields of Wills, Trusts, and Probate.
						</p>
						<p>
							The company is engineered to provide legal services to every day
							people.
						</p>
						<br />
						<p>
							Our mission statement is simple:{' '}
							<span className="highlight-text">
								"Hard working legal services for hard working people."
							</span>
						</p>
						<p>
							Call and talk with us:{' '}
							<a href="tel:+1239-790-4477" className="phone-link">
								<FontAwesomeIcon icon={faPhoneFlip} size="lg" /> 239-790-4477
							</a>
						</p>
					</Card.Body>
				</Card>
			</Container>
		</div>
	);
};
export default About;
