import { Container, Card } from 'react-bootstrap';

const Wills = () => {
	return (
		<div className="page">
			<Container fluid>
				<Card className="practice-area-card mt-4 mb-4 shadow-card">
					<Card.Body>
						<Card.Title>
							<h1 className="text-center soft-highlight">Wills</h1>
							<hr />
						</Card.Title>
						<ul className="mt-4">
							<li>
								Last Will and Testament -{' '}
								<span className="highlight-text">
									Don't let the government decide who receives your assets after
									death
								</span>
							</li>
							<li>
								Appoint a Personal Representative/Executor -{' '}
								<span className="highlight-text">
									This person will administer your estate and ensure that your
									beneficiaries receive their inheritance
								</span>
							</li>
							<li>
								Guardianship Nomination -{' '}
								<span className="highlight-text">
									What happens to your minor children when you die? Nominate a
									Guardian in your will
								</span>
							</li>
							<li>
								Durable Powers of Attorney -{' '}
								<span className="highlight-text">Plan for the unexpected</span>
							</li>
							<li>
								Healthcare Surrogate/Guardian Forms –{' '}
								<span className="highlight-text">
									Nominate someone to help with your care
								</span>
							</li>
							<li>
								Living Wills -{' '}
								<span className="highlight-text">
									Specify what actions should be taken for your health if you
									can't
								</span>
							</li>
							<li>
								Supplemental Needs Trust –{' '}
								<span className="highlight-text">
									Provide for & protect assets of individuals with physical,
									psychiatric, or intellectual disabilities while still
									qualifying for Medicaid
								</span>
							</li>
							<li>
								Charitable Planning -{' '}
								<span className="highlight-text">
									Voluntarily giving help to those in need
								</span>
							</li>
						</ul>
					</Card.Body>
				</Card>
			</Container>
		</div>
	);
};
export default Wills;
