import { FormLabel, Card, Form } from 'react-bootstrap';

const SubmissionForm = ({ submission }) => {
	const arr = Object.keys(submission);
	console.log(arr);
	const ignoreProps = ['name', 'address', 'city', 'state', 'zipcode', 'phone'];
	return (
		<div>
			<Form>
				<Form.Select>
					<option value="probate">
						Probate Preliminary Information Submissions
					</option>
					<option value="willstrusts">
						Wills & Trusts Preliminary Information Submissions
					</option>
				</Form.Select>
			</Form>
			<Card className="contact-card">
				<Card.Body>
					<h4 className="d-flex">{submission.name.answer}</h4>
					<div className="d-flex">{submission.address.answer}</div>
					<div className="d-flex">
						{submission.city.answer}, {submission.state.answer}{' '}
						{submission.zipcode.answer}
					</div>
					<div className="d-flex">{submission.phone.answer}</div>
				</Card.Body>
			</Card>
			{arr.map((prop, i) => {
				return (
					<div key={i}>
						{submission[prop].hasOwnProperty('question') &&
							!ignoreProps.includes(prop) && (
								<>
									<FormLabel>{submission[prop].question}</FormLabel>
									{typeof submission[prop].answer !== 'object' && (
										<div>{submission[prop].answer}</div>
									)}
									<hr />
								</>
							)}
					</div>
				);
			})}
		</div>
	);
};
export default SubmissionForm;
