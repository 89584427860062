import { useState, useEffect } from 'react';
import { Container, Card, Form, Row, Col, Button } from 'react-bootstrap';
import api from '../AxiosConfig';
import PhoneInput from '../components/formComponents/PhoneInput';
import LoadingOverlay from '../components/LoadingOverlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {
	const [form, setForm] = useState({
		name: '',
		phone: '',
		email: '',
		message: '',
	});
	const [errors, setErrors] = useState({});

	const [sendingMessage, setSendingMessage] = useState(false);
	const [messageSuccess, setMessageSuccess] = useState(false);
	const [messageFailure, setMessageFailure] = useState(false);
	const [initialSubmit, setInitialSubmit] = useState(false); //track first time the submit button is clicked to trigger validation

	const handleForm = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};

	const sendMessage = () => {
		setInitialSubmit(true);
		if (validateInputs()) {
			//Start the Loading Overlay
			setSendingMessage(true);
			//use axios to send message
			api.post('/contact', form).then((res) => {
				setSendingMessage(false);
				if (res.status === 200) {
					//Message sent
					setMessageSuccess(true);
				} else {
					//Oops
					setMessageFailure(true);
				}
			});
		}
	};

	const validateInputs = () => {
		let errors = {};
		const { name, phone, email, message } = form;
		if (name === '') {
			errors = { ...errors, name: 'Please provide your Name.' };
		}
		if (phone === '') {
			errors = { ...errors, phone: 'Please provide your Phone Number.' };
		}
		if (email === '') {
			errors = { ...errors, email: 'Please provide your Email Address.' };
		}
		if (message === '') {
			errors = { ...errors, message: 'What did you want to say?' };
		}
		setErrors({ ...errors });

		if (Object.keys(errors).length > 0) {
			return false;
		}
		return true;
	};

	//Validate inputs on a timer to remove warnings as for is filled
	useEffect(() => {
		if (initialSubmit) {
			let timer = setTimeout(() => {
				validateInputs();
			}, 300);
			return () => clearTimeout(timer);
		}
	}, [form]);

	//Clear the success or failure message on a timeout of 5 seconds
	useEffect(() => {
		if (messageSuccess || messageFailure) {
			let timer = setTimeout(() => {
				setForm({
					name: '',
					phone: '',
					email: '',
					message: '',
				});
				setMessageFailure(false);
				setMessageSuccess(false);
				setInitialSubmit(false);
			}, 5000);
			return () => clearTimeout(timer);
		}
	}, [messageSuccess, messageFailure]);

	return (
		<div id="contact" className="page">
			<Container>
				<Row>
					<Col md={{ span: '6', offset: '3' }} className="mt-4 mb-4">
						{messageSuccess && (
							<Card id="message-success-card">
								<Card.Header>
									<Card.Title className="text-center">Message Sent</Card.Title>
								</Card.Header>
								<Card.Body className="text-center">
									Your message was delivered successfully. One of our lawyers
									will reach out to you as soon as possible.
									<FontAwesomeIcon id="contact-envelope" icon={faEnvelope} />
								</Card.Body>
							</Card>
						)}
						{messageFailure && (
							<Card>
								<Card.Header>
									<Card.Title className="text-center">
										Message Failed
									</Card.Title>
								</Card.Header>
								<Card.Body className="text-center">
									Oops, the message was not sent. Please try again.
									<FontAwesomeIcon
										className="mt-3"
										id="contact-warning"
										icon={faCircleExclamation}
									/>
								</Card.Body>
							</Card>
						)}
						{!messageSuccess && !messageFailure && (
							<Card className="shadowbox">
								<Card.Header>
									<h1 className="text-center soft-highlight">
										Do you have a law inquiry?
									</h1>
								</Card.Header>
								<Card.Body>
									<Form>
										<Form.Group>
											<Form.Label>Name:</Form.Label>
											<Form.Control
												required
												type="text"
												name="name"
												value={form.name}
												onChange={handleForm}
												isInvalid={errors.name}
											/>
											{errors.name && <Form.Text>{errors.name}</Form.Text>}
										</Form.Group>{' '}
										<PhoneInput
											phone={form.phone}
											setPhone={(val) => setForm({ ...form, phone: val })}
											isInvalid={errors.phone}
										/>
										<Form.Group>
											<Form.Label>Email:</Form.Label>
											<Form.Control
												required
												type="email"
												name="email"
												value={form.email}
												onChange={handleForm}
												isInvalid={errors.email}
											/>
											{errors.email && <Form.Text>{errors.email}</Form.Text>}
										</Form.Group>
										<Form.Group>
											<Form.Label>Message:</Form.Label>
											<Form.Control
												as="textarea"
												name="message"
												value={form.message}
												onChange={handleForm}
												isInvalid={errors.message}
											/>
											{errors.message && (
												<Form.Text>{errors.message}</Form.Text>
											)}
										</Form.Group>
										<div className="d-grid mt-2">
											<Button
												variant="primary"
												name="submit"
												onClick={sendMessage}
											>
												Send
											</Button>
										</div>
									</Form>
								</Card.Body>
							</Card>
						)}
					</Col>
				</Row>
			</Container>
			{sendingMessage && <LoadingOverlay />}
		</div>
	);
};
export default Contact;
