//Modal that will navigate to another Route after timeout

import { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
	faCheckCircle,
	faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TimedAlertModal = ({
	show,
	message,
	title,
	timeout,
	navigateTo,
	handleClose,
	closeButton = false,
	icon,
}) => {
	const navigate = useNavigate();

	useEffect(() => {
		if (show && navigateTo && timeout) {
			let timer = setTimeout(() => {
				navigate(navigateTo);
			}, timeout);
			return () => clearTimeout(timer);
		}
	}, []);

	return (
		<Modal className="timed-alert-modal" show={show} onHide={handleClose}>
			<Modal.Header className="text-center">
				<Modal.Title style={{ width: '100%' }}>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="text-center">
					<div>{message}</div>
					{icon === 'success' ? (
						<FontAwesomeIcon
							className="timed-alert-modal-icon success mt-3"
							icon={faCheckCircle}
						/>
					) : (
						<FontAwesomeIcon
							className="timed-alert-modal-icon failure mt-3"
							icon={faExclamationCircle}
						/>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer>
				{closeButton && (
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				)}
			</Modal.Footer>
		</Modal>
	);
};
export default TimedAlertModal;
