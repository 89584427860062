import { Col, Container, Row, Card, Button, Form } from 'react-bootstrap';
import api from '../AxiosConfig';
import { useEffect, useState } from 'react';
import SubmissionForm from '../components/SubmissionForm';

const AdminPNC = () => {
	const [practiceArea, setPracticeArea] = useState('probate');
	const [submissions, setSubmissions] = useState([]);

	//Load Preliminary Info submissions
	useEffect(() => {
		console.log('Should submit');
		api
			.post('/admin-pnc', { practiceArea: practiceArea })
			.then((res) => {
				console.log(res);
				if (res.status === 200) {
					setSubmissions(res.data);
				}
			})
			.catch((err) => console.log(err));
	}, [practiceArea]);

	function testEmail() {
		api
			.post('/test-email', { locals: submissions[0] })
			.then((res) => {
				console.log(res);
			})
			.catch((err) => console.log(err));
	}

	return (
		<div id="admin-pnc">
			<Container className="page">
				<Row>
					<Col>
						<Form>
							<Form.Group>
								<Form.Select
									name="practiceArea"
									onChange={(e) => setPracticeArea(e.target.value)}
								>
									<option value="probate">Probate</option>
									<option value="willstrusts">Wills and Trusts</option>
								</Form.Select>
							</Form.Group>
							<Form.Group>
								<Button type="button" variant="primary" onClick={testEmail}>
									Test Email
								</Button>
							</Form.Group>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col>
						<Card>
							<Card.Header>
								<h1>Admin</h1>
							</Card.Header>
							<Card.Body>
								{submissions.map((submission, i) => {
									return <SubmissionForm submission={submission} />;
								})}
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
export default AdminPNC;
